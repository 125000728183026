@import 'styles/variables.scss';

.verify-code {
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .verify-code__title {
    color: $text-gray-900;
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 2.25rem;
    margin: 0;
  }
  .verify-code__desc {
    color: $text-gray-500;
    font-weight: 400;
    margin-top: 4px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: unset !important;
    padding: 0 !important;

    .form__logo-wrapper {
      svg {
        height: 42px;
      }
    }

    .form__resend {
      padding: 16px;
      background-color: $bg-gray-100;
      border-radius: 8px;
      width: 100%;
      .form__resend__action {
        .btn-primary {
          svg {
            margin-right: 0;
          }
        }
      }
      .note {
        margin: 0;
        margin-bottom: 12px;
        font-weight: 500;
        color: $text-gray-600;
        .email {
          color: $text-blue-600;
        }
      }
    }

    .extra-action {
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 20px;
      column-gap: 20px;
      .action-item {
        display: flex;
        align-items: center;
        color: $text-gray-700;
        svg {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
      }
    }
  }
}

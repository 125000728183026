@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.chat-page {
  height: 100vh;
  .chat-component {
    width: calc(100% - 300px);
  }
  .jump-to-latest {
    transition-duration: .2s;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    background: transparent;
    margin-top: -64px;

    .btn {
      padding: 0.5rem 2rem;
      background: white;
      border-radius: 9999px;
      --tw-shadow: 0 3px 8px rgba(169,201,199,.25);
      tab-size: 4;
      -webkit-text-size-adjust: 100%;
      border: 0 solid;
      --tw-border-opacity: 1;
      border-color: rgba(229,231,235,var(--tw-border-opacity));
      --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
      --tw-ring-offset-width: 0px;
      --tw-ring-offset-color: #fff;
      --tw-ring-color: rgba(59,130,246,.5);
      --tw-ring-offset-shadow: 0 0 #0000;
      --tw-ring-shadow: 0 0 #0000;
      box-sizing: border-box;
      display: flex;
      --tw-translate-x: 0;
      --tw-rotate: 0;
      --tw-skew-x: 0;
      --tw-skew-y: 0;
      --tw-scale-x: 1;
      --tw-scale-y: 1;
      transform: translate3d(var(--tw-translate-x),var(--tw-translate-y),0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
      --tw-translate-y: 0px;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      border-radius: 9999px;
      --tw-bg-opacity: 1;
      background-color: rgba(255,255,255,var(--tw-bg-opacity));
      padding-left: 2rem;
      padding-right: 2rem;
      padding-top: .5rem;
      padding-bottom: .5rem;
      font-size: .875rem;
      line-height: 1.25rem;
      --tw-text-opacity: 1;
      color: #f26c48;
      opacity: 1;
      transition-duration: .2s;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }
  }
}


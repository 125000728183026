@import "styles/variables.scss";
@import "styles/mixins.scss";

.templates-page {
  padding: 0 16px;
  max-width: 96rem;
  margin: auto;
  @media (min-width: 640px) {
    padding: 0 20px;
  }

  .templates-wrapper {
    padding-bottom: 9rem;

    .templates-wrapper__top {
      padding-bottom: 12px;
      padding-top: 18px;
      row-gap: 12px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;

      .templates-wrapper__top__title {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @media (min-width: 640px) {
          column-gap: 20px;
          row-gap: 12px;
          flex-wrap: nowrap;
        }

        h2 {
          color: $text-gray-900;
          font-weight: 700;
          font-size: 24px;
          margin: 0;
          @media (min-width: 640px) {
            font-size: 30px;
            @include truncate;
          }
        }
      }

      .templates-wrapper__top__action {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 20rem;
        @media (min-width: 768px) {
          min-width: 400px;
        }

        .btn-action {
          border: none;
          width: 36px;
          height: 36px;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: none;
          &.active {
            background: $bg-gray-100;
          }
          &:hover {
            background: $bg-gray-200;
          }

          svg {
            width: 20px;
            height: 20px;
            color: $text-gray-400;
          }
        }

        .ant-divider {
          height: 1.5rem;
          width: 0.125rem;
          background-color: $bg-gray-300;
          margin: 0 1rem;
        }

        .templates-wrapper__top__action__layout {
          margin-right: 12px;
          column-gap: 8px;
        }

        .templates-wrapper__top__action__search {
          display: flex;
          flex: 1 1;
          .input-outline {
            align-items: center;
            .ant-input-prefix {
              width: 16px;
              height: 16px;
              margin-right: 6px;
              svg {
                color: $text-gray-400;
              }
            }

            input::placeholder {
              color: $text-gray-400;
            }
          }
          kbd {
            display: none;
            color: $text-gray-400;
            font-weight: 500;
            padding: 0 6px;
            border: 1px solid $border-gray-200;
            border-radius: 4px;
            @media (min-width: 640px) {
              display: inline-flex;
            }
          }
        }
      }
    }

    .templates-wrapper__tag {
      padding-top: 2rem;
      .ant-radio-group {
        display: flex;
        column-gap: 8px;
        row-gap: 8px;
        flex-wrap: wrap;
        .ant-radio-button-wrapper {
          border-radius: 9999px;
          border: 1px solid $border-gray-300;
          &.ant-radio-button-wrapper-checked {
            border: 1px solid $border-blue-400;
            background-color: $bg-blue-400;
            color: $white;
          }
          &::before {
            display: none;
          }
        }
      }
    }

    .templates-wrapper__items {
      margin-top: 32px;
    }
  }
}

@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.chat-component {
  height: 100%;
  width: 100%;
  background-color: $bg-gray-50;
  .chat-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;

    .chat-area {
      display: flex;
      width: 100%;
      flex-direction: column;
      overflow: auto;
      flex: 1 1 0%;
      .chat-area-inner {
        display: flex;
        margin: auto;
        width: 100%;
        max-width: 80rem;
        flex: 1 1 0%;
        flex-direction: column;
        padding: 0 0.5rem;

        .logo-area {
          text-align: center;
          padding: 1rem;
          justify-content: center;
          align-items: center;
          max-width: 24rem;
          max-height: 4rem;
          width: 100%;
          display: flex;
          margin: 1rem auto;

          h1 {
            display: flex;
            align-items: center;
            justify-content: center;
            .title {
              font-weight: 700;
              font-size: 1.25rem;
              line-height: 1.75rem;
            }

            .tag {
              .ant-tag {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;
                margin-left: 12px;
                font-weight: 700;
                font-size: 0.5rem;
                color: $text-purple-600;
                padding: 0.25rem 0.5rem;
                border: 1px solid $border-purple-600;
                border-radius: 9999px;
                background-image: linear-gradient(to right, #f3e8ff, #d9dfff);
                max-height: 22px;
              }
            }
          }
        }

        .chat-box {
          display: flex;
          flex: 1 1 0%;
          width: 100%;
          flex-direction: column;
          padding: 1rem;
        }
      }
    }

    .chat-tool {
      display: flex;
      margin: auto;
      max-width: 80rem;
      flex-direction: column;
      padding: 1rem;
      width: 100%;
      z-index: 1;

      .chat-tool__toggle-collapse {
        display: flex;
        justify-content: flex-start;
        .btn-collapse {
          padding: 0 0.75rem;
          min-height: 32px;
          color: $text-indigo-500;
          &.show {
            svg {
              transform: rotate(180deg);
            }
          }
          svg {
            width: 24px;
            height: 24px;
            margin-left: 4px;
            transition: 0.2s all cubic-bezier(0.4, 0, 0.2, 1);
          }
        }

        .btn-reset {
          color: $text-indigo-300;
          font-weight: 600;
          padding: 0.375rem 0.125rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 4px;
          &:hover {
            color: $text-indigo-500;
          }

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }

      .chat-tool__suggestion {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        overflow: hidden;
        padding-top: 0;
        opacity: 0;
        transition: 0.5s all cubic-bezier(0.4, 0, 0.2, 1);
        column-gap: 8px;
        row-gap: 8px;
        max-height: 0;
        &.show {
          opacity: 1;
          padding-top: 1rem;
          max-height: 150px;
        }

        .suggestion-item {
          display: flex;
          align-items: center;
          border-radius: 9999px;
          background: $bg-indigo-50;
          padding: 0.25rem 0.75rem;
          font-size: 0.75rem;
          font-weight: 500;
          line-height: 1.25rem;
          color: $text-indigo-700;
          border: none;
        }
      }

      .chat-form {
        .limit-character {
          text-align: right;
          font-size: 0.75rem;
          line-height: 1rem;
          opacity: 0.7;
          margin-top: 16px;
          display: block;
        }
        textarea.ant-input {
          border-width: 0;
        }

        .form-item {
          background-color: $white;
          border: 1px solid $border-gray-300;
          border-radius: 0.375rem;
          overflow-y: auto;
          max-height: 5rem;
          margin-top: 4px;
          .ant-form-item {
            margin-bottom: 0;
          }
          @media (min-width: 32rem) {
            max-height: 8rem;
          }
          @media (min-width: 42rem) {
            max-height: 16rem;
          }
        }

        .prompt-wrapper {
          margin-top: 0.5rem;
          display: flex;
          width: 100%;
          align-items: center;
          svg {
            width: 16px;
            height: 16px;
          }
        }

        .form-action {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 16px;
          .form-action__left {
            .btn-clear {
              font-weight: 600;
              font-size: 0.875rem;
              line-height: 1.25rem;
              max-height: 28px;
              padding: 0.375rem 0.75rem;
              column-gap: 4px;
              svg {
                width: 16px;
                height: 16px;
                position: relative;
                top: 1px;
              }
            }
          }

          .form-action__right {
            display: flex;
            align-items: center;
            column-gap: 12px;
            .include-gg {
              display: flex;
              align-items: center;
              column-gap: 8px;
              .ant-switch {
                background-color: $bg-gray-200;
                &.ant-switch-checked {
                  background-color: $bg-indigo-600;
                }
              }
              .desc {
                font-size: 0.875rem;
                line-height: 1.25rem;
              }
            }
            .btn-send {
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: 60px;
              svg {
                width: 20px;
                height: 20px;
                transform: rotate(90deg);
                position: relative;
                left: 2px;
              }
            }
          }
        }
      }
    }
  }
}

@import 'styles/variables.scss';

.home-page {
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  @media (min-width: 640px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .home-page__tool-section {
    h2 {
      font-weight: 700;
      padding-bottom: 1.5rem;
      margin: 0;
      font-size: 16px;
    }
  }

  .home-page__more {
    margin-top: 1.5rem;
    h2 {
      font-weight: 700;
      margin: 0;
      margin-bottom: 1.5rem;
      font-size: 16px;
    }
  }

  .card {
    display: flex;
    margin-top: 0.75rem;
    width: 100%;
    justify-content: space-between;
    border-radius: 0.5rem;
    border: 1px solid $border-gray-200;
    background-color: $bg-gray-50;
    transition: 0.8s all cubic-bezier(0.4, 0, 0.2, 1);
    &:hover {
      background-color: $bg-blue-400;
      .card-inner {
        .card-inner__top {
          svg {
            color: $white;
          }
          h2 {
            color: $white;
          }
        }
        .card-inner__body {
          p {
            color: $white;
          }
        }
      }
    }

    @media (min-width: 1280px) {
      margin-top: 0;
    }
    .card-inner {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 1rem;
      gap: 30px;

      .card-inner__top {
        display: flex;
        align-items: center;
        svg {
          width: 32px;
          height: 32px;
          color: $text-gray-500;
          transition: 1.2s all cubic-bezier(0.4, 0, 0.2, 1);
        }
        h2 {
          margin: 0;
          font-weight: 700;
          padding: 0.25rem;
          font-size: 16px;
          transition: 0.8s all cubic-bezier(0.4, 0, 0.2, 1);
        }
      }

      .card-inner__body {
        p {
          color: $text-gray-400;
          padding: 0.25rem;
          font-size: 0.875rem;
          line-height: 1.25rem;
          margin: 0;
          transition: 0.8s all cubic-bezier(0.4, 0, 0.2, 1);
        }
      }

      .card-inner__bottom {
        align-self: baseline;
        .btn-chat {
          color: $text-gray-600;
          font-weight: 600;
          font-size: 0.75rem;
          line-height: 1rem;
          border-radius: 0.375rem;
        }
      }
    }
  }

  .card-start {
    display: flex;
    width: 100%;
    border-radius: 0.375rem;
    border: 1px solid $border-gray-200;
    background-color: $white;
    height: 100%;

    .card-inner {
      display: flex;
      margin: auto;
      flex-direction: column;
      max-width: 24rem;
      align-items: center;
      padding: 1.5rem;
      text-align: center;

      .card-inner__top {
        .icon-wrapper {
          display: flex;
          height: 2.5rem;
          width: 2.5rem;
          align-items: center;
          justify-content: center;
          border-radius: 9999px;
          background-color: $bg-gray-100;
          color: $text-gray-500;
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
      .card-inner__body {
        p {
          margin: 0;
          color: $text-gray-500;
          margin-top: 0.75rem;
        }
      }
      .card-inner__bottom {
        margin-top: 1rem;
        .btn-more {
          font-weight: 600;
          color: $text-gray-600;
          font-size: 0.875rem;
          line-height: 1.25rem;
        }
      }
    }
  }

  .card-refer {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 0.375rem;
    border: 1px solid $border-gray-200;
    background-color: $white;
    padding: 1.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $text-gray-400;
    .card-refer__top {
      margin-bottom: 0.75rem;
      @media (min-width: 768px) {
        display: flex;
        align-items: center;
      }

      h2 {
        font-size: 1.5rem;
        line-height: 2rem;
        margin-bottom: 0.75rem;
        font-weight: 700;
        letter-spacing: -0.025em;
        color: $text-gray-800;
        @media (min-width: 768px) {
          margin-bottom: 0;
        }
      }

      .ant-tag {
        font-weight: 500;
        line-height: 1rem;
        color: $text-green-600;
        font-size: 0.875rem;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        background-color: $bg-green-50;
        border: 1px solid $border-green-200;
        border-radius: 9999px;
        margin-left: 12px;
        display: inline-block;
      }
    }

    .card-refer__body {
      p {
        font-size: 1rem;
        color: $text-gray-500;
        line-height: 1.5rem;
        margin: 0;
        @media (min-width: 768px) {
          line-height: 1.75rem;
        }
      }
    }

    .card-refer__bottom {
      margin-top: 0.75rem;
      .btn-copy {
        color: $text-gray-600;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.5rem;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        height: auto;
        svg {
          width: 1rem;
          height: 1rem;
          opacity: 0.6;
          margin-right: 8px;
        }
      }
    }
  }
}

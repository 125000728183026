@import "styles/variables.scss";

.setting-layout {
  .setting-content {
    margin-top: 4px;
    @media (min-width: 1024px) {
      margin-left: 288px;
    }
  }
}

@import "styles/variables.scss";

#toolbar {
  display: flex;
  align-items: center;
  column-gap: 4px;
  border-left: none;
  border-top: none;
  border-right: none;
  border-bottom-color: $border-gray-200;
  box-shadow: 0 0 transparent, 0 0 transparent, 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 3rem;
  left: 280px;
  z-index: 999;
  width: 100%;
  background-color: $white;
  .toolbar-btn {
    float: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    color: rgba(51, 65, 85, 1);
    &:hover {
      background-color: rgba(234, 237, 255);
    }
    &.ql-active {
      color: rgba(30, 67, 255);
      background-color: rgba(234, 237, 255);
    }
  }

  button[value="3"],
  button[value="4"] {
    svg {
      width: 16px;
      height: 18px;
    }
  }

  svg {
    width: 18px;
    height: 18px;
  }

  .ant-divider {
    height: 24px;
    margin: 0 4px;
  }
}

@import 'styles/variables.scss';

.prompt-chat {
  height: 100%;
  width: 300px;
  position: fixed;
  right: 0;
  top: 0;

  .prompt-chat-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    .prompt-chat-inner__title {
      font-size: 1.25rem;
      margin: 0;
      padding-left: 1rem;
      padding-top: 0.5rem;
    }
    .prompt-chat-menu {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      padding-bottom: 0.75rem;
      overflow-x: hidden;
      overflow-y: auto;
      height: 100%;
      margin-top: 0.75rem;

      .ant-collapse-item {
        .ant-collapse-header {
          align-items: center;
          border-top: 1px solid $border-gray-200;
          // border-bottom: 1px solid $border-gray-200;
          padding-top: 0;
          padding-bottom: 0;
        }
        .ant-collapse-content-box {
          padding-top: 0;
          padding-right: 5px;
          padding-left: 5px;
        }
      }

      .prompt-chat-menu__item {
        &.loading {
          .ant-menu-item {
            padding-left: 0;
          }
        }

        .prompt-chat-menu__item__title {
          margin-bottom: 0;
          padding-top: 0.75rem;
          padding-bottom: 0.75rem;
          font-size: 0.875rem;
          font-weight: 700;
          line-height: 1rem;
          color: $text-gray-500;
          margin-top: 0;
        }
      }
      .ant-menu {
        .ant-menu-item {
          height: 32px;
          line-height: 32px;
          &.ant-menu-item-selected {
            color: $text-indigo-700;
            background: $bg-indigo-50;
          }
        }
      }
    }
  }
}

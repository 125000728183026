@import "styles/variables.scss";
@import "styles/mixins.scss";

.main-menu {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  height: 100%;
  @media (min-width: 1024px) {
    width: 288px;
    display: flex;
    flex-direction: column;
  }

  .menu-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 28px;
    max-height: 100vh;
    border-right: 1px solid $border-gray-200;
    overflow-y: auto;
    width: 100%;
    @media (min-width: 640px) {
      width: 288px;
    }

    .menu-top {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-shrink: 0;
      justify-content: center;
      padding: 0 20px;

      .menu-top__logo {
        display: flex;
        justify-content: space-between;
        width: 100%;
        img {
          max-width: 100%;
        }
        svg {
          height: 28px;
        }
        .ant-tag {
          text-transform: uppercase;
          font-size: 12px;
          line-height: 18px;
          padding: 5px 18px;
          background-color: $bg-green-100;
          color: $text-green-700;
          border-color: transparent;
          border-radius: 9999px;
        }
      }

      .menu-top__project {
        text-align: left;
        width: 100%;
        display: inline-block;
        position: relative;
        margin-top: 24px;
        .ant-btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: auto;
          background-color: $bg-gray-50;
          border-color: rgba(203, 213, 225, 0.5);
          padding: 8px 16px;
          @include truncate;

          &:hover {
            color: unset;
            border-color: rgb(203 213 225);
            .title {
              color: $text-gray-500;
            }
            .project-name {
              color: $black !important;
            }

            .icon svg {
              color: $text-gray-600;
            }
          }

          .title {
            text-align: left;
            margin-top: 4px;
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 0;
            color: $text-gray-500;
          }

          .project-name {
            font-weight: 600;
            text-align: left;
            margin-bottom: 0;
            margin-top: 0;
            font-size: 16px;
          }

          .icon {
            display: flex;
            margin-right: -4px;
            svg {
              transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
              width: 20px;
              height: 20px;
              color: $text-gray-400;
            }
          }
        }
      }
    }

    .menu-body {
      display: flex;
      flex: 1 1 0%;
      flex-direction: column;
      position: relative;
      padding: 18px 0;

      .menu-body__wrapper {
        padding: 0 10px;
        padding-bottom: 4px;

        .ant-menu-item {
          padding-inline: 0;
          margin-top: 0;
          margin-bottom: 0;
          & ~ .ant-menu-item {
            margin-top: 8px;
          }

          .ant-menu-title-content {
            display: block;
          }

          &.ant-menu-item-selected {
            background-color: transparent;
            .menu-item {
              color: $text-blue-400;
              font-weight: 600;
              svg {
                fill: $text-blue-400;
                path {
                  &:nth-child(2) {
                    fill: $white;
                  }
                }
              }
            }
          }

          &:hover {
            background-color: transparent;
          }

          .menu-item {
            display: flex;
            align-items: center;
            font-weight: 500;
            color: $text-gray-600;
            font-size: 16px;
            padding-inline: 8px;
            transition: border-color 0.3s, background 0.3s,
              padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

            &::before {
              display: none;
            }

            &.btn-docs {
              border-radius: 8px;
            }
            &.btn-add {
              height: 40px;
              width: 40px;
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 8px;
              svg {
                margin: 0;
                fill: $text-gray-400;
              }
            }

            &:hover {
              background-color: $bg-gray-50;
            }
            svg {
              width: 20px;
              height: 20px;
              margin-right: 8px;
            }
          }
        }
      }
    }

    .menu-extra {
      padding: 0 18px;
      margin: 10px 0;

      .menu-extra__credit {
        width: 100%;
        padding: 16px;
        background-color: $bg-gray-50;
        border: 1px solid $border-gray-200;
        border-radius: 8px;
        .menu-extra__credit__title {
          display: flex;
          align-items: center;
          margin-bottom: 4px;
          span {
            font-size: 14px;
            padding-left: 8px;
            font-weight: 500;
            color: $text-gray-600;
          }
        }

        .menu-extra__credit__progress {
          .ant-progress {
            margin-top: -4px;
            margin-bottom: 4px;
            .ant-progress-bg {
              background-color: 0;
              background-image: linear-gradient(to right, #1e43ff, #b318ff);
            }
          }

          .menu-extra__credit__progress__detail {
            color: $text-blue-500;
            font-weight: 700;
            font-size: 10px;
          }

          .more-credit {
            margin-top: 12px;
            a {
              text-decoration: underline;
              color: $text-blue-600;
              font-weight: 500;
              font-size: 12px;
            }
          }
        }
      }
    }

    .menu-bottom {
      .menu-bottom__wrapper {
        padding-bottom: 4px;
        padding-inline: 10px;
        .ant-menu-item {
          padding-inline: 0;
          margin-top: 0;
          margin-bottom: 0;

          .ant-menu-title-content {
            display: block;
          }

          &.ant-menu-item-selected {
            background-color: transparent;
            .menu-item {
              color: $text-blue-400;
              font-weight: 600;
              svg {
                fill: $text-blue-400;
              }
            }
          }

          &:hover {
            background-color: transparent;
          }

          .menu-item {
            display: flex;
            align-items: center;
            font-weight: 500;
            color: $text-gray-600;
            font-size: 16px;
            padding-inline: 8px;
            transition: border-color 0.3s, background 0.3s,
              padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

            &::before {
              display: none;
            }

            &:hover {
              background-color: $bg-gray-50;
            }
            svg {
              width: 20px;
              height: 20px;
              margin-right: 8px;
            }
          }
        }
      }
    }

    .quick-search {
      display: flex;
      flex-direction: column;
      padding: 0 18px;
      margin: 8px 0;
      .btn-quick-search {
        width: 100%;
        color: $text-gray-400;
        font-size: 12px;
        padding-right: 56px;
        justify-content: flex-start;
        height: 32px;
        &:hover {
          border-color: rgb(148 163 184);
        }
      }

      .shortcut {
        position: absolute;
        top: 0;
        right: 0;
        pointer-events: none;
        padding: 6px;
        padding-left: 0;

        kbd {
          font-size: 14px;
          font-weight: 500;
          color: $text-gray-400;
          border-radius: 4px;
          border: 1px solid $border-gray-200;
          padding: 0 4px;
        }
      }

      .profile-item {
        text-align: left;
        display: flex;
        margin-top: 12px;
        .btn-profile {
          font-weight: 500;
          font-size: 14px;
          padding: 4px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          margin: 4px 0;
          flex: 1 1;
          border: none;
          box-shadow: none;
          &:hover {
            background-color: $bg-gray-100;
            color: $black;
          }
          .ant-avatar {
            width: 24px;
            height: 24px;
            font-weight: 700;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .username {
            text-align: left;
            flex: 1 1;
            margin-left: 8px;
            @include truncate;
          }
          svg {
            width: 24px;
            height: 24px;
            color: $text-gray-500;
            margin-left: 8px;
          }
        }
      }
    }
  }
}

.popover-project {
  width: 247px;
  .popover-project__body {
    padding: 4px 0;
    overflow-y: auto;
    max-height: 180px;
    margin-left: -12px;
    margin-right: -12px;
    border-top: 1px solid $divide-gray-100;
    border-bottom: 1px solid $divide-gray-100;
    margin-top: 12px;
    .ant-menu {
      .ant-menu-item {
        margin: 0;
        width: 100%;
        border-radius: 0;
        &:hover {
          background-color: $bg-gray-100;
        }
        &.ant-menu-item-selected {
          color: $text-gray-700;
          background-color: $bg-gray-100;
        }
      }
    }
  }
  .popover-project__action {
    margin-left: -12px;
    margin-right: -12px;
    .ant-menu {
      .ant-menu-title-content {
        display: block;
      }
      .ant-menu-item {
        & ~ .ant-menu-item {
          border-top: 1px solid rgb(241 245 249);
        }
        margin: 0;
        width: 100%;
        border-radius: 0;
        font-weight: 700;
        &:hover {
          background-color: $bg-gray-100;
        }
      }
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.popover-profile {
  width: 247px;
  .ant-popover-inner {
    padding: 0 0 4px;
  }
  .popover-profile__top {
    display: flex;
    align-items: center;
    padding: 0 12px;
    background-color: $bg-gray-50;
    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }

    .profile-wrapper {
      padding: 16px 16px 12px;
      color: $text-gray-500;
      .username {
        font-size: 14px;
        margin-bottom: -4px;
        margin-top: 0;
        @include truncate;
      }
      a {
        color: $text-blue-600;
        font-size: 12px;
      }
    }
  }

  .popover-profile__body {
    padding: 4px 0;
    .ant-menu {
      & ~ .ant-menu-item {
        border-top: 1px solid rgb(241 245 249);
      }
      .ant-menu-item {
        margin: 0;
        width: 100%;
        border-radius: 0;
        &:hover {
          background-color: $bg-gray-100;
        }

        svg {
          width: 20px;
          height: 20px;
          margin-right: 8px;
          color: $text-gray-400;
        }
      }

      .ant-menu-item-divider {
        margin-block: 4px;
      }
    }
  }
}

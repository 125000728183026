@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.recipes-detail-page {
  margin: auto;
  max-width: 96rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin-top: 0.25rem;
  @media (min-width: 640px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .recipes-detail-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    .recipes-detail-top__left {
      display: flex;
      align-items: center;
      h2 {
        margin-top: 0;
        color: $text-gray-900;
        font-weight: 700;
        display: flex;
        align-items: center;
        column-gap: 8px;
        margin-bottom: 0.5rem;
        line-height: 1.75rem;
        font-size: 1.5rem;
        a {
          color: $text-gray-900;
        }
        @media (min-width: 640px) {
          font-size: 1.875rem;
          line-height: 2.25rem;
          @include truncate;
        }

        .chev {
          display: flex;
          align-items: center;
          color: $text-gray-400;
          svg {
            width: 2rem;
            height: 2rem;
          }
        }
      }

      .btn-share {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $text-gray-600;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.25rem;
        margin-left: 12px;
        svg {
          width: 1rem;
          height: 1rem;
          margin-right: 8px;
        }
      }
    }
    .recipes-detail-top__right {
      .btn-how-to-use {
        display: flex;
        align-items: center;
        border-radius: 9999px;
        background-color: $bg-gray-100;
        padding: 0.5rem 0.75rem;
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1rem;
        color: $text-gray-500;
        &:hover {
          border: 1px solid rgb(203 213 225);
        }

        svg {
          width: 1rem;
          height: 1rem;
          margin-right: 8px;
        }
      }
    }
  }

  .recipes-detail-content {
    .ant-form {
      display: flex;
      flex-direction: column;

      .youtube-wrapper {
        display: flex;
        align-items: center;
        column-gap: 24px;
        .input-wrapper {
          display: flex;
          flex: 1 1 0%;
          .ant-form-item {
            width: 100%;
            margin-bottom: 0;
          }
        }
        iframe {
          height: 9rem;
          width: 12rem;
          border-radius: 0.75rem;
        }
        .video-preview {
          height: 9rem;
          width: 12rem;
          border-radius: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $white;
          border: 1px solid $border-gray-200;
          p {
            margin: 0;
            padding: 0.25rem 0.5rem;
            background-color: $bg-gray-100;
            border-radius: 9999px;
            font-size: 0.75rem;
            font-weight: 500;
            line-height: 1rem;
            letter-spacing: -0.025em;
            color: $text-gray-500;
            &.invalid {
              color: $text-red-500;
              background-color: $bg-red-100;
            }
          }
        }
      }

      .ant-collapse-header {
        background-color: $bg-gray-100;
        border-radius: 0.5rem;
        .ant-collapse-header-text {
          font-weight: 500;
          color: $text-gray-500;
        }
        .extra {
          text-decoration-line: underline;
          color: $text-gray-400;
        }
        .ant-collapse-expand-icon {
          svg {
            color: $text-gray-400;
          }
        }
      }

      .action-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 24px;

        .btn-save {
          font-weight: 600;
          font-size: 1rem;
          line-height: 1.5rem;
          margin-left: 0;
        }

        .btn-delete {
          font-weight: 600;
          color: $text-gray-600;
          font-size: 0.875rem;
          line-height: 1.25rem;
          border-radius: 0.375rem;
          height: 40px;
        }
      }
    }
  }
}

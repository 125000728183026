@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.recipes-page {
  .recipes-top {
    border-bottom: 1px solid $border-gray-200;
    background-color: $white;
    padding-top: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    @media (min-width: 640px) {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }

    .recipes-top__inner {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      padding-bottom: 1.5rem;

      h1 {
        font-size: 2.25rem;
        line-height: 2.5rem;
        font-weight: 500;
        margin: 0;
      }

      .recipes-top__inner__action {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .recipes-top__inner__action__search {
          display: flex;
          flex: 1 1;
          .input-outline {
            align-items: center;
            .ant-input-prefix {
              width: 16px;
              height: 16px;
              margin-right: 6px;
              svg {
                color: $text-gray-400;
              }
            }

            input::placeholder {
              color: $text-gray-400;
            }
          }
          kbd {
            display: none;
            color: $text-gray-400;
            font-weight: 500;
            padding: 0 6px;
            border: 1px solid $border-gray-200;
            border-radius: 4px;
            @media (min-width: 640px) {
              display: inline-flex;
            }
          }
        }
      }
    }
  }

  .recipes-content {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-top: 0.25rem;
    article {
      max-width: 96rem;
      margin: auto;
      padding-left: 1rem;
      padding-right: 1rem;
      @media (min-width: 640px) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }

      .recipes-content__popular {
        padding-bottom: 2.5rem;

        .recipes-content__popular__top {
          margin-bottom: 1.25rem;
          h2 {
            margin-bottom: 0.5rem;
            font-size: 1.5rem;
            line-height: 2rem;
            font-weight: 600;
            letter-spacing: -0.025em;
            color: $text-gray-700;
          }
          p {
            color: $text-gray-500;
            margin: 0;
            a {
              color: $text-blue-700;
            }
          }
        }

        .recipes-content__popular__list {
          display: flex;
          padding: 0.25rem;
          overflow-x: scroll;
          gap: 1.25rem;
          .recipes-content__popular-item {
            position: relative;
            width: 20rem;
            flex-shrink: 0;
            flex-grow: 0;
            border-radius: 0.375rem;
            background-color: $white;
            padding: 0.75rem;
            outline: 1px solid $border-gray-200;

            .featured-img {
              margin-bottom: 1rem;
              position: relative;
              img {
                border-radius: 0.375rem;
                object-fit: cover;
                height: 100%;
                max-width: 100%;
              }
            }

            h4 {
              margin-top: 0;
              margin-bottom: 0.5rem;
              font-weight: 600;
              letter-spacing: -0.025em;
              font-size: 1.25rem;
              line-height: 1.75rem;
              color: $text-gray-700;
            }

            .desc {
              max-height: 3rem;
              color: $text-gray-500;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }

            .overlay {
              transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
              opacity: 0;
              padding: 2rem;
              background-color: rgba(51, 65, 85, 0.2);
              border-radius: 0.375rem;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              &:hover {
                opacity: 1;
              }

              .btn-recipe {
                background-color: $bg-blue-400;
                font-size: 1rem;
                line-height: 1.5rem;
                font-weight: 600;
                color: $white;
                height: 40px;
                border: none;
              }
            }
          }
        }
      }

      .title-your-recipes {
        margin: 0;
        margin-bottom: 0.5rem;
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 600;
        letter-spacing: -0.025em;
        color: $text-gray-700;
      }

      .your-recipes {
        .ant-table-thead {
          .ant-table-cell {
            color: $text-gray-500;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 0.75rem;
            line-height: 1rem;
            background-color: $bg-gray-50;
          }
        }
        .ant-table-tbody {
          .ant-table-row {
            cursor: pointer;
            &:hover {
              .ant-table-cell {
                background-color: $bg-gray-50;
              }
            }
          }
          .ant-table-cell {
            color: $text-gray-800;
            font-weight: 500;
          }
        }

        .ant-pagination {
          .ant-pagination-total-text {
            margin-right: auto;
            line-height: 1.25rem;
            color: $text-neutral-500;
          }
          .ant-pagination-prev,
          .ant-pagination-next {
            width: 32px;
            height: 32px;
          }
          .ant-pagination-item {
            width: 32px;
            height: 32px;
            a {
              line-height: 30px;
            }
          }
        }
      }

      .btn-gradient {
        display: inline-flex;
        align-items: center;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.25rem;
        border-radius: 9999px;
        height: 32px;
        min-width: 69px;
        svg {
          width: 12px;
          height: 12px;
          margin-right: 6px;
          position: relative;
          top: 1px;
        }
      }
      .btn-edit {
        font-weight: 600;
        border-radius: 9999px;
        height: 36px;
        padding: 0.5rem 1rem;
        background-color: $bg-slate-100;
        color: $black;
        margin-left: 8px;
      }

      .icon-status {
        &.fade {
          color: $text-gray-400;
        }
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

@import 'styles/variables.scss';

.legend {
  position: relative;
  border-radius: 0.25rem;
  background-color: $white;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1rem;
  padding-top: 2rem;
  color: $text-gray-700;
  border: 1px solid $border-gray-200;
  .legend-tag {
    position: absolute;
    top: -0.75rem;
    left: 1rem;
    border-radius: 0.25rem;
    background-color: $white;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1rem;
    letter-spacing: -0.025em;
    color: $text-gray-700;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  }
  .legend-content {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    max-width: 42rem;
    color: $text-gray-600;
  }
}

@import "styles/variables.scss";
@import "styles/mixins.scss";

.chat-item {
  position: relative;
  display: flex;
  width: auto;
  flex-direction: column;
  max-width: 100%;
  align-items: flex-start;
  align-self: flex-start;
  //white-space: normal;
  overflow-wrap: break-word;
  padding-bottom: 1rem;
  font-size: 0.875rem;
  line-height: 2.5rem;

  @keyframes color-animation {
    0% { opacity: 1; }
    40% { opacity: 0.7; }
    100% { opacity: 1; }
  }

  @keyframes text-color-animation {
    0% { opacity: 1; }
    40% { opacity: 0.5; }
    100% { opacity: 1; }
  }

  .animated-text {
    animation: color-animation 3s infinite;
    p {
      animation: text-color-animation 3s infinite;
    }
  }

  &.user {
    align-items: flex-end;
    align-self: flex-end;
  }

  &:hover,
  &.show {
    .chat-item__action {
      max-height: 3rem !important;
      padding-top: 0.375rem;
      opacity: 1 !important;
      transition-delay: 0.2s !important;
    }
  }

  @media screen and (min-width: 28rem) {
    font-size: 1rem;
    line-height: 2rem;
    max-width: 90%;
  }
  @media screen and (min-width: 36rem) {
    padding-bottom: 0;
  }
  @media screen and (min-width: 56rem) {
    max-width: 85%;
  }

  .chat-item__content {
    position: relative;
    width: 100%;
    max-width: 100%;
    border-radius: 1rem;
    border: 1px solid $border-gray-200;
    background-color: $white;
    padding: 1rem;

    @media screen and (min-width: 28rem) {
      width: auto;
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .chat-item__message {
      overflow-wrap: break-word;
      p {
        margin-top: 0;
        margin-bottom: 0;
        line-height: 1.8rem;
      }
      .single-code {
        background: #fffbfb;
        color: #f26c48;
        border-radius: 0.2rem 0.3rem;
        padding: 0.2rem;
      }
      .syntax-highlighter {
        &:hover .btn-clipboard {
            display: block;
        }
      }
      .btn-clipboard {
        position: absolute;
        right: 23px;
        margin-top: 6px;
        display: none;
      }
    }

    .chat-item__action {
      margin-top: 0 !important;
      display: flex;
      max-height: 0;
      width: 100%;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      overflow: hidden;
      opacity: 0;
      transition: 0.5s all cubic-bezier(0.4, 0, 0.2, 1);

      .btn-action {
        opacity: 0.7;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.25rem;
        text-align: center;
        padding: 0.375rem 0.75rem;
        transition: 0.2s all cubic-bezier(0.4, 0, 0.2, 1);
        background-color: transparent;
        border: none;
        box-shadow: none;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .chat-item__user {
    .ant-avatar {
      height: 1.5rem;
      width: 1.5rem;
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      &.bot {
        width: 28px;
        height: 28px;
      }
      img {
        max-width: 100%;
        height: 100%;
      }
    }
  }
}

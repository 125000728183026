@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.setting-menu {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  height: 100%;
  background-color: $bg-gray-50;

  @media (min-width: 1024px) {
    width: 256px;
    display: flex;
    flex-direction: column;
  }

  .menu-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 28px;
    max-height: 100vh;
    border-right: 1px solid $border-gray-200;
    overflow-y: auto;
    width: 100%;
    // @media (min-width: 640px) {
    //   width: 256px;
    // }

    .menu-top {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-shrink: 0;
      justify-content: center;
      padding: 0 12px;

      .menu-top__logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: max-content;
        width: 32px;
        height: 32px;
        margin-left: 16px;
        svg {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
        .ant-tag {
          text-transform: uppercase;
          font-size: 12px;
          line-height: 18px;
          padding: 5px 18px;
          background-color: $bg-green-100;
          color: $text-green-700;
          border-color: transparent;
          border-radius: 9999px;
        }
      }
    }

    .menu-body {
      display: flex;
      flex: 1 1 0%;
      flex-direction: column;
      position: relative;
      padding: 18px 12px;
      padding-left: 36px;

      .menu-body__section {
        margin-bottom: 24px;

        .ant-menu {
          margin-left: -24px;
          background-color: transparent;
        }

        .menu-body__section__title {
          font-weight: 500;
          color: $text-gray-500;
          font-size: 0.875rem;
          line-height: 1.25rem;
          margin-bottom: 8px;
          margin-top: 0;
        }

        .ant-menu-item {
          padding-inline: 0;
          margin-top: 0;
          margin-bottom: 0;
          margin-inline: 0;
          height: auto;
          line-height: normal;
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
          padding-left: 1.5rem;
          padding-right: 1.5rem;

          &:hover {
            background-color: $bg-gray-200;
          }

          // & ~ .ant-menu-item {
          //   margin-top: 8px;
          // }

          .ant-menu-title-content {
            display: block;
          }

          &.ant-menu-item-selected {
            background-color: $bg-gray-200;
            .menu-item {
              padding-inline: 0;
              svg {
                fill: $text-blue-400;
                path {
                  &:nth-child(2) {
                    fill: $white;
                  }
                }
              }
            }
          }

          &:hover {
            background-color: $bg-gray-200;
          }

          .menu-item {
            display: flex;
            align-items: center;
            font-weight: 500;
            color: $text-gray-600;
            font-size: 14px;
            transition: border-color 0.3s, background 0.3s,
              padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

            &::before {
              display: none;
            }

            &.btn-docs {
              border-radius: 8px;
            }
            &.btn-add {
              height: 40px;
              width: 40px;
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 8px;
              svg {
                margin: 0;
                fill: $text-gray-400;
              }
            }

            svg {
              width: 20px;
              height: 20px;
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
}

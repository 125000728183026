@import 'styles/variables.scss';

.community-header {
  position: sticky;
  background-color: $white;
  z-index: 50;
  top: 0;
  border-bottom: 1px solid $border-gray-200;
  padding-left: 1rem;
  padding-right: 1rem;
  box-shadow: inset 0 0 0 1px rgb(229 231 235);
  @media (min-width: 1024px) {
    overflow-y: visible;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .logo {
    svg {
      width: 60px;
      height: 60px;
    }
  }
}

@import "styles/variables.scss";
@import "styles/mixins.scss";

.document-detail-page {
  padding: 16px;
  .document-detail__top {
    padding: 0 0.5rem;
    border-bottom: 1px solid $border-gray-200;
    height: 3rem;
    display: flex;
    align-items: center;
    background-color: $white;
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    .document-detail__top-left {
      display: flex;
      align-items: center;

      .btn-back {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        color: rgba(71, 85, 105);
        border-radius: 0.25rem;
        height: 24.5px;
        width: 31.5px;
        border: none;
        box-shadow: none;
        &:hover {
          background-color: rgba(248, 250, 252);
        }
        &:active,
        &:focus {
          background-color: rgba(241, 245, 249);
        }
        svg {
          width: 17.5px;
          height: 17.5px;
        }
      }

      .document-detail__top-left__title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 4px;

        .btn-title {
          max-width: 28rem;
          display: flex;
          align-items: center;
          height: 28px;
          padding: 0.25rem 0.5rem;
          color: $text-gray-700;
          border-radius: 0.25rem;
          font-weight: 500;
          border: none;
          box-shadow: none;
          @include truncate;

          &:hover {
            background-color: rgba(248, 250, 252);
          }
          &:active,
          &:focus {
            background-color: rgba(241, 245, 249);
          }
        }
      }
    }
  }

  .document-detail__aside {
    position: fixed;
    top: 3rem;
    bottom: 0;
    left: 0;
    width: 280px;
    height: 100%;

    .document-detail__aside__content {
      overflow-y: auto;
      height: 100%;
      border-right: 1px solid $border-gray-200;
      .ant-form {
        padding: 1rem;
        .form-inner {
          margin-top: 0.75rem;
          .ant-form-item {
            margin-bottom: 24px;
            label {
              width: 100%;
            }
          }
          .form-item-label {
            display: flex;
            align-items: center;
            margin-bottom: 4px;
            width: 100%;
            .icon-info {
              position: relative;
              top: 1px;
              display: flex;
              align-items: center;
              margin-left: 8px;
              svg {
                width: 1rem;
                height: 1rem;
                color: $text-gray-300;
              }
            }

            .form-item-label__switch {
              margin-left: auto;
              .ant-switch {
                background-color: $bg-gray-200;
              }
              .ant-switch.ant-switch-checked {
                background-color: $bg-green-500;
              }
            }
          }

          .tag-count {
            display: block;
            text-align: right;
            color: rgba(0, 0, 0, 0.45);
            white-space: nowrap;
            pointer-events: none;
            margin-top: -20px;
          }
        }
      }

      .document-detail__aside__output-length {
        padding: 1rem;
        padding-bottom: 1.25rem;
        border-top: 1px solid $border-gray-200;
        border-bottom: 1px solid $border-gray-200;
        .title {
          margin-top: 0;
          margin-bottom: 0.5rem;
          font-size: 0.75rem;
          font-weight: 500;
          color: $text-gray-400;
        }

        .output-wrapper {
          .ant-radio-group {
            display: flex;
            align-items: center;
            column-gap: 4px;
            .ant-radio-button-wrapper {
              border-radius: 0.5rem;
              border-inline-start-width: 1px;
              width: 28.95px;
              height: 24.5px;
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              border: none;
              &::before {
                display: none;
              }

              .ant-radio-button {
                font-weight: 600;
                font-size: 0.875rem;
                color: $text-gray-600;
                border-radius: 0.5rem;
                background-color: $bg-gray-100;
                z-index: 0;
                &.ant-radio-button-checked {
                  color: $text-blue-400;
                  background-color: $bg-blue-100;
                }
              }

              span:nth-child(2) {
                z-index: 5;
              }
            }
          }
        }
      }
    }
  }

  .document-detail__shortcut {
    position: fixed;
    z-index: 20;
    bottom: 0;
    left: 0;
    width: 100%;
    .document-detail__shortcut-inner {
      display: flex;
      justify-content: center;
      margin: auto;
      max-width: 56rem;
      .document-detail__shortcut-action {
        display: flex;
        align-items: center;
        width: 100%;
        border: 1px solid $border-gray-200;
        border-top-left-radius: 0.375rem;
        border-top-right-radius: 0.375rem;
        padding: 0.75rem;
        column-gap: 10px;
        background-color: $white;
        @media (min-width: 640px) {
          width: auto;
        }

        .btn-compose {
          min-width: 168px;
          max-width: 16rem;
          font-weight: 600;
          color: $white;
          line-height: 1.5rem;
          font-size: 1rem;
          background-image: linear-gradient(90deg, #b318ff, #1e43ff);
          border-radius: 0.5rem;
          width: 100%;
          height: 49px;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .btn-reset {
          width: 56px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0.5rem;
          border: 1px solid $border-gray-200;
          padding: 0;
          height: 49px;
          color: $text-gray-400;
          svg {
            width: 21px;
            height: 21px;
          }
        }

        .document-detail__shortcut__output-length {
          // padding: 1rem;
          // padding-bottom: 1.25rem;
          .title {
            margin-top: 0;
            margin-bottom: 0.5rem;
            font-size: 0.75rem;
            font-weight: 500;
            color: $text-gray-400;
          }

          .output-wrapper {
            .ant-radio-group {
              display: flex;
              align-items: center;
              column-gap: 4px;
              .ant-radio-button-wrapper {
                border-radius: 0.5rem;
                border-inline-start-width: 1px;
                width: 28.95px;
                height: 24.5px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                &::before {
                  display: none;
                }

                .ant-radio-button {
                  font-weight: 600;
                  font-size: 0.875rem;
                  color: $text-gray-600;
                  border-radius: 0.5rem;
                  background-color: $bg-gray-100;
                  z-index: 0;
                  &.ant-radio-button-checked {
                    color: $text-blue-400;
                    background-color: $bg-blue-100;
                  }
                }

                span:nth-child(2) {
                  z-index: 5;
                }
              }
            }
          }
        }
      }
    }
  }

  .quill-container {
    position: fixed;
    right: 0;
    top: 3rem;
    bottom: 0;
    left: 280px;
    overflow-y: scroll;
    background-color: $white;
    padding: 0;
    height: 100%;
    .quill {
      padding-top: 41px;
      .ql-container {
        border: none;
        max-width: 64rem;
        margin: auto;

        .ql-editor {
          height: 100%;
          outline: none;
          overflow-y: auto;
          tab-size: 4;
          text-align: left;
          white-space: pre-wrap;
          word-wrap: break-word;
          padding-top: 4rem;
          padding-bottom: 5rem;
          color: rgba(30, 41, 59);
          background-color: $white;
          min-height: 100vh;
          border-radius: 0.25rem;
          @media (min-width: 1024px) {
            font-size: 1.25rem;
            line-height: 1.8;
          }
        }
      }
    }
  }
}

@import "styles/variables.scss";

.login-page {
  min-height: 100vh;
  .form-wrapper {
    padding: 2rem 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    .form {
      width: 336px;
      margin: 0;
      .form__logo-wrapper {
        margin-bottom: 16px;
        svg {
          height: 42px;
        }
      }

      .title {
        letter-spacing: -0.025em;
        line-height: 2rem;
        font-weight: 800;
        font-size: 26px;
        margin-top: 1rem;
        margin-bottom: 0;
      }

      .form__third-party-login {
        margin-top: 28px;
      }

      .form__divider {
        position: relative;
        margin-top: 20px;
        .form__divider__line-wrapper {
          display: flex;
          align-items: center;
          position: absolute;
          left: 0;
          top: 1px;
          width: 100%;
          height: 100%;
          .form__divider__line {
            border-top: 1px solid $border-gray-300;
            width: 100%;
          }
        }
        .form__divider__content {
          position: relative;
          line-height: 1rem;
          font-size: 0.75rem;
          display: flex;
          justify-content: center;
          span {
            color: $text-gray-500;
            padding: 0 0.5rem;
            background: $white;
          }
        }
      }

      .form__login {
        margin-top: 28px;
        .form__login__action {
          margin-top: 20px;
        }
      }

      .form__extra {
        margin-top: 20px;
        p {
          margin-bottom: 0;
          text-align: center;
          font-size: 12px;
          color: $text-gray-400;
          a {
            color: $text-blue-400;
          }
        }
      }
    }
  }
}

@import 'styles/variables.scss';

.recipes-community {
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 80rem;
  margin: auto;
  @media (min-width: 1024px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  article {
    margin: auto;
    max-width: 56rem;

    .recipes-community__top {
      display: flex;
      align-items: center;
      padding-bottom: 1.5rem;
      @media (min-width: 768px) {
        padding-bottom: 2rem;
      }

      .avatar-wrapper {
        position: relative;

        .ant-avatar {
          height: 5rem;
          width: 5rem;
          overflow: hidden;
          border-radius: 9999px;
          outline: 2px solid transparent;
          outline-offset: 2px;
          transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
          box-shadow: 0 0 0 4px rgba(243, 244, 246);
          display: flex;
          align-items: center;
          justify-content: center;

          .ant-avatar-string {
            line-height: normal;
          }
        }
      }

      .recipe-top {
        flex-grow: 1;
        margin-left: 20px;
        h1 {
          font-weight: 600;
          letter-spacing: -0.025em;
          color: $text-gray-900;
          font-size: 1.25rem;
          line-height: 1.75rem;
          margin: 0;
          @media (min-width: 768px) {
            font-size: 1.5rem;
            line-height: 2rem;
          }
        }
        p {
          margin: 0;
          margin-top: 4px;
          max-width: 42rem;
          font-size: 0.875rem;
          line-height: 1.25rem;
          color: $text-gray-500;
        }
      }
    }

    .recipes-community__content {
      .youtube-wrapper {
        margin-bottom: 3rem;
        iframe {
          width: 100%;
          height: 330px;
          overflow: hidden;
          border-radius: 0.375rem;
        }
      }

      .action-wrapper {
        display: none;
        align-items: center;
        column-gap: 8px;
        @media (min-width: 1024px) {
          display: flex;
        }

        .btn-save {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $text-gray-700;
          font-weight: 500;
          padding: 0.75rem 1.5rem;
          height: auto;
          border-radius: 0.25rem;
          border-color: $border-gray-200;
          &:hover {
            background-color: $bg-gray-50;
          }
          svg {
            width: 1rem;
            height: 1rem;
            margin-right: 8px;
          }
        }
        .btn-run {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-grow: 1;
          padding: 0.875rem 1.5rem;
          height: auto;
          font-weight: 500;
          color: $white;
          border-radius: 0.25rem;
          svg {
            width: 1rem;
            height: 1rem;
            margin-right: 8px;
          }
        }
      }

      .users-wrapper {
        margin-top: 1.5rem;
        align-items: center;
        overflow: hidden;
        display: none;
        @media (min-width: 1024px) {
          display: flex;
        }

        .ant-avatar {
          background-color: $bg-gray-100;
          color: $text-gray-900;
          box-shadow: 0 0 0 2px rgba(255, 255, 255);
          & ~ .ant-avatar {
            margin-left: -0.25rem;
          }
        }
      }

      .help-wrapper {
        margin-top: 1.5rem;
        border-radius: 0.25rem;
        background-color: $white;
        padding: 1rem;
        box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
        border: 1px solid $border-gray-200;

        h3 {
          margin-bottom: 0.5rem;
          border-bottom: 1px solid $border-gray-100;
          padding-bottom: 0.5rem;
          margin-top: 0;
          font-size: 0.875rem;
          line-height: 1.25rem;
          font-weight: 500;
          color: $text-gray-700;
        }

        .link-wrapper {
          padding-left: 1.25rem;
          li {
            margin-bottom: 0.75rem;
            list-style-type: disc;
            color: $text-gray-300;
            a {
              font-size: 0.875rem;
              line-height: 1.25rem;
              font-weight: 500;
              text-decoration: underline;
              color: $text-gray-600;
            }
          }
        }
      }
    }

    .action-wrapper-mobile {
      display: flex;
      margin-bottom: 1.5rem;
      align-items: center;
      @media (min-width: 1024px) {
        display: none;
      }

      .btn-save {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $text-gray-700;
        font-weight: 500;
        padding: 0.75rem 1.5rem;
        height: auto;
        border-radius: 0.25rem;
        border-color: $border-gray-200;
        &:hover {
          background-color: $bg-gray-50;
        }
        svg {
          width: 1rem;
          height: 1rem;
          margin-right: 8px;
        }
      }
      .btn-run {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        padding: 0.875rem 1.5rem;
        height: auto;
        font-weight: 500;
        color: $white;
        border-radius: 0.25rem;
        svg {
          width: 1rem;
          height: 1rem;
          margin-right: 8px;
        }
      }
    }
  }
}

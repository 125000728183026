@import 'styles/variables.scss';

.setting-profile-page {
  min-height: 100vh;
  .form-wrapper {
    padding: 2rem 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    // min-height: 100vh;
    padding-top: 3rem;
    padding-bottom: 3rem;
    .title {
      letter-spacing: -0.025em;
      line-height: 2rem;
      font-weight: 800;
      font-size: 26px;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .form__onboarding {
      width: 400px;
    }

    .form__login__action {
      .ant-btn {
        margin-left: 0;
      }
    }
  }
  .ant-select.input-outline {
    padding-bottom: 0;
  }
}

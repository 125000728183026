@import 'styles/variables.scss';

.community-footer {
  .footer-wrapper {
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    overflow: hidden;
    max-width: 80rem;
    margin: auto;
    @media (min-width: 640px) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    @media (min-width: 1024px) {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    nav {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: -0.5rem -1.25rem;
      .nav-item {
        padding: 0.5rem 1.25rem;
        a {
          font-size: 1rem;
          line-height: 1.5rem;
          color: $text-gray-500;
        }
      }
    }

    .social-wrapper {
      margin-top: 2rem;
      display: flex;
      justify-content: center;
      column-gap: 24px;
      a {
        color: $text-gray-400;
        &:hover {
          color: $text-gray-500;
        }
        svg {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }

    .copyright {
      margin-top: 2rem;
      text-align: center;
      font-size: 1rem;
      line-height: 1.5rem;
      color: $text-gray-400;
      margin-bottom: 0;
    }
  }
}

@import "styles/variables.scss";

.remirror-editor {
  padding: 4px;
  &:focus-visible {
    border-color: #4096ff;
    box-shadow: 0 0 0 2px rgb(5 145 255 / 10%);
    border-inline-end-width: 1px;
    outline: 0;
  }
}

@import "styles/variables.scss";

.output-item {
  background: $bg-green-50;
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid $border-gray-200;
  cursor: pointer;

  &:hover {
    background: $bg-green-300;
    .btn-action {
      opacity: 1 !important;
      background-color: $white !important;
    }
  }

  .output-item__action-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .output-item__action {
      display: flex;
      align-items: center;
      column-gap: 8px;

      .btn-action {
        opacity: 0.2;
        color: $text-gray-400;
        line-height: 1rem;
        padding: 0;
        border-radius: 0.375rem;
        background-color: transparent;
        border-color: transparent;
        width: 32px;
        height: 32px;
        &:hover {
          &.btn-favorite {
            color: $text-yellow-500;
          }
          background-color: $white;
          color: $text-gray-600;
          border-color: $border-gray-200;
        }
        svg {
          width: 1rem;
          height: 1rem;
        }
      }
    }

    .output-item__created {
      display: flex;
      align-items: center;
      span {
        font-size: 0.75rem;
        line-height: 1rem;
        color: $text-gray-400;
      }
    }
  }

  .output-item__content {
    color: $text-gray-800;
    line-height: 1.75rem;
    // font-weight: 500;
    font-size: 1rem;
    white-space: pre-wrap;
    width: 100%;
    margin-bottom: 0.75rem;
    margin-top: 0.5rem;
  }
}

@import "styles/variables.scss";

.template-item {
  transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
  padding: 1.5rem;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  border: 1px solid rgb(226 232 240);

  &:hover {
    border: 1px solid rgb(203 213 225);
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
      0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
    .btn-favorite {
      display: block;
    }
  }

  .btn-favorite {
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: none;

    .ant-btn {
      border: none;
      width: 32px;
      height: 32px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: none;
      color: $text-gray-200;
      &:hover {
        color: $text-yellow-400;
        background: $bg-gray-200;
      }
      svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }

  .template-info {
    .template-logo {
      width: 64px;
      height: 64px;
      background-color: $bg-gray-100;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1.5rem;
      svg {
        width: 2.75rem;
        height: 2.75rem;
      }
    }

    h3 {
      font-weight: 600;
      color: $text-gray-700;
      font-size: 1.125rem;
      margin-bottom: 0.5rem;
    }

    p {
      color: $text-gray-500;
      flex: 1 1;
    }
  }
}

$text-gray-900: rgb(15 23 42);
$text-gray-800: rgb(30 41 59);
$text-gray-700: rgba(51, 65, 85);
$text-gray-600: rgba(71, 85, 105);
$text-gray-500: rgba(100, 116, 139);
$text-gray-400: rgba(148, 163, 184);
$text-gray-300: rgba(203, 213, 225);
$text-gray-200: rgb(226 232 240);
$bg-gray-300: rgb(203 213 225);
$bg-gray-200: rgb(226 232 240);
$bg-gray-100: rgb(241 245 249);
$bg-gray-50: rgb(248 250 252);

$text-blue-700: rgb(29 78 216);
$text-blue-600: rgb(0 30 183);
$text-blue-500: rgb(0 38 234);
$text-blue-400: rgba(30, 67, 255);
$bg-blue-700: rgb(29 78 216);
$bg-blue-400: rgb(30 67 255);
$bg-blue-100: rgba(217, 223, 255);
$border-blue-400: rgb(30 67 255);

$border-gray-300: rgba(203, 213, 225);
$border-gray-200: rgb(226 232 240);
$border-gray-100: rgba(243, 244, 246);

$text-green-700: rgb(21 128 61);
$bg-green-500: rgba(34, 197, 94);
$bg-green-300: rgba(134, 239, 172, 0.05);
$bg-green-100: rgb(220 252 231);
$bg-green-50: rgb(240 253 244);
$border-green-200: rgb(187 247 208);
$text-green-600: rgb(22 163 74);

$text-yellow-500: rgb(234 179 8);
$text-yellow-400: rgb(250 204 21);

$bg-gold-50: rgb(254 236 206);
$text-gold-600: rgb(201 126 4);

$divide-gray-100: rgb(241 245 249);

$white: #fff;
$black: #000;

$text-neutral-500: rgb(125 144 184);

$text-purple-600: rgb(125 33 190);
$border-purple-600: rgb(125 33 190);

$text-indigo-700: rgb(67 56 202);
$text-indigo-500: rgb(99 102 241);
$text-indigo-300: rgb(165 180 252);
$bg-indigo-600: rgb(79 70 229);
$bg-indigo-50: rgb(238 242 255);

$bg-slate-100: rgb(241 245 249);

$bg-red-100: rgb(254 226 226);
$text-red-500: rgb(239 68 68);

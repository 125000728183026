@import "styles/variables.scss";
@import "styles/mixins.scss";

.documents-page {
  padding: 16px;
  max-width: 96rem;
  margin: auto;
  margin-top: 8px;
  @media (min-width: 640px) {
    padding: 16px 20px;
  }

  .documents-top {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .documents-top__left {
      h2 {
        margin: 0;
        color: $text-gray-900;
        font-weight: 700;
        margin-bottom: 1rem;
        @media (min-width: 640px) {
          font-size: 1.875rem;
          line-height: 2.25rem;
          @include truncate;
        }
      }

      .documents-top__menu {
        display: flex;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.25rem;
        margin-bottom: 1.5rem;
        color: $text-gray-500;
        .ant-menu {
          border-bottom: none;
          .ant-menu-item {
            padding-left: 0;
            line-height: 32px;
            &::after {
              left: 0;
            }
          }
        }
      }

      .documents-top__selected-items {
        display: flex;
        align-items: center;
        margin-bottom: 0.625rem;
        .selected-number {
          width: 6rem;
          font-weight: 500;
        }

        .btn-action {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 0.75rem;
          line-height: 1rem;
          padding: 0;
          color: $text-gray-500;
          width: 32px;
          height: 32px;
          border: none;
          box-shadow: none;
          &:hover {
            color: $text-gray-600;
            background-color: $bg-gray-100;
          }
          svg {
            width: 1rem;
            height: 1rem;
          }
        }
      }
    }

    .documents-top__right {
      display: flex;
      align-items: center;
      .documents-top__right__search {
        display: flex;
        flex-direction: column;
        flex: 1 1 0%;

        .input-outline {
          align-items: center;
          .ant-input-prefix {
            width: 16px;
            height: 16px;
            margin-right: 6px;
            svg {
              color: $text-gray-400;
            }
          }

          input::placeholder {
            color: $text-gray-400;
          }
        }
        kbd {
          display: none;
          color: $text-gray-400;
          font-weight: 500;
          padding: 0 6px;
          border: 1px solid $border-gray-200;
          border-radius: 4px;
          @media (min-width: 640px) {
            display: inline-flex;
          }
        }
      }
    }
  }

  .documents-body {
    display: flex;
    flex-direction: column;
    .ant-table-thead {
      .ant-table-cell {
        color: $text-gray-500;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 0.75rem;
        line-height: 1rem;
        background-color: $bg-gray-50;
      }
    }
    .ant-table-tbody {
      .ant-table-row {
        cursor: pointer;
        &:hover {
          .ant-table-cell {
            background-color: $bg-gray-50;
          }
        }
      }
      .ant-table-cell {
        color: $text-gray-800;
        font-weight: 500;
      }
    }

    .ant-pagination {
      .ant-pagination-total-text {
        margin-right: auto;
        line-height: 1.25rem;
        color: $text-neutral-500;
      }
      .ant-pagination-prev,
      .ant-pagination-next {
        width: 32px;
        height: 32px;
      }
      .ant-pagination-item {
        width: 32px;
        height: 32px;
        a {
          line-height: 30px;
        }
      }
    }
  }
}

@import "./variables.scss";

.btn-primary {
  font-size: 16px;
  color: $white;
  letter-spacing: -0.025em;
  font-weight: 600;
  background-color: rgba(30, 67, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  svg {
    width: 18px;
    height: 18px;
    margin-right: 12px;
  }
  &.large {
    height: 50px;
  }
  &.font-large {
    font-size: 18px;
  }
}

.btn-outline {
  font-size: 16px;
  color: $text-gray-700;
  letter-spacing: -0.025em;
  font-weight: 600;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  height: auto;
  svg {
    width: 18px;
    height: 18px;
  }
  &.large {
    height: 50px;
  }
  &.font-large {
    font-size: 18px;
  }
}

.btn-gradient {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  margin-left: 0.5rem;
  position: relative;
  background-image: linear-gradient(to right, #b318ff, #1e43ff);
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: $white !important;
  height: auto;
  border: none;
  &:hover {
    background-image: linear-gradient(to right, #b44dff, #516eff);
  }
  &:active {
    background-image: linear-gradient(to right, #9527e5, #0026ea);
  }

  svg {
    width: 18px;
    height: 18px;
  }
  &.large {
    height: 50px;
  }
  &.font-large {
    font-size: 18px;
  }
}

.btn-transparent {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  position: relative;
  background: transparent;
  box-shadow: none;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  height: auto;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 18px;
    height: 18px;
  }
}

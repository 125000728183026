@import "styles/variables.scss";
@import "styles/mixins.scss";

.template-detail {
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  .template-detail__left {
    overflow: auto;
    border-right: 1px solid $border-gray-200;
    background-color: $bg-gray-50;
    width: 50%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    .template-detail__form {
      display: flex;
      height: 100%;
      flex-direction: column;

      .template-detail__info {
        position: sticky;
        z-index: 10;
        top: 0;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $border-gray-200;
        padding: 1rem 1.5rem;
        background: $white;
        .template-detail__logo {
          margin-right: 1.5rem;
          .template-detail__logo-inner {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.5rem;
            height: 2.5rem;
            color: $text-gray-500;
            background-color: $bg-gray-100;
            border-radius: 0.75rem;
            box-shadow: inset 0 0 0 3px rgb(241 245 249);
            svg {
              width: 1.75rem;
              height: 1.75rem;
            }
          }
        }

        .template-detail__name {
          h2 {
            font-weight: 700;
            font-size: 1.25rem;
            color: $text-gray-900;
            margin: 0;
            @include truncate;
          }
          p {
            margin: 0;
            font-size: 0.875rem;
            line-height: 1.25rem;
            color: $text-gray-500;
          }
        }
      }

      .template-detail__content {
        flex: 1 1 0%;
        padding: 0.75rem;
        @media (min-width: 1280px) {
          padding: 1.5rem;
          padding-bottom: 7rem;
        }

        .template-detail__content__form-item {
          .ant-form-item {
            margin-bottom: 0;
          }
          & ~ .template-detail__content__form-item {
            margin-top: 24px;
          }
        }

        .template-detail__translation {
          padding: 1.5rem;
          border: 1px solid $border-gray-200;
          border-radius: 0.375rem;
          margin-top: 32px;
          .template-detail__translation__top {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            @media (min-width: 640px) {
              flex-wrap: wrap;
            }
            @media (min-width: 768px) {
              justify-content: flex-start;
              align-items: center;
              flex-direction: row;
            }
            @media (min-width: 1024px) {
              flex-wrap: nowrap;
            }

            h3 {
              display: flex;
              align-items: center;
              font-size: 0.875rem;
              line-height: 1.25rem;
              font-weight: 500;
              margin: 0;
              @media (min-width: 768px) {
                margin-right: 0.75rem;
              }

              .ant-tag {
                border-radius: 9999px;
                margin-right: 0.5rem;
                background: $bg-gold-50;
                padding: 0.25rem 0.75rem;
                font-weight: 500;
                font-size: 0.75rem;
                color: $text-gold-600;
                border-color: transparent;
              }

              span {
                @media (min-width: 768px) {
                  white-space: nowrap;
                }
              }

              svg {
                margin-left: 0.5rem;
                width: 1rem;
                color: $text-gray-500;
              }
            }

            .template-detail__translation__top__note {
              margin-bottom: 0;
              margin-top: 0.5rem;
              font-size: 0.75rem;
              line-height: 1rem;
              @media (min-width: 768px) {
                margin-top: 0;
              }

              span {
                color: $text-gray-500;
              }
            }
          }

          .template-detail__translation__content {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            margin-top: 1rem;
            column-gap: 16px;
            .ant-form-item {
              margin-bottom: 0;
            }
            @media (min-width: 768px) {
              align-items: flex-end;
              flex-direction: row;
            }

            .arrow-right {
              height: 42px;
              display: flex;
              align-items: center;
              @media (min-width: 768px) {
                align-self: flex-end;
              }
              svg {
                width: 1rem;
                color: $text-gray-500;
              }
            }

            .ant-select-selector {
              height: 42px;
              .ant-select-selection-item {
                line-height: 36px;
              }
            }
          }
        }
      }

      .template-detail__bottom {
        z-index: 20;
        @media (min-width: 1280px) {
          position: sticky;
          width: 100%;
          bottom: 0;
          left: 0;
        }
        .template-detail__bottom__inner {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0.75rem 0.75rem;
          background: $bg-gray-50;
          border-bottom: 1px solid $border-gray-200;
          @media (min-width: 1280px) {
            padding: 0.75rem 1.5rem;
            background: $white;
            border: none;
            border-top: 1px solid $border-gray-200;
          }

          .btn-outline {
            font-size: 0.875rem;
            padding: 0.375rem 0.75rem;
            font-weight: 600;
            color: $text-gray-600;
            span {
              line-height: normal;
            }
          }

          .template-detail__bottom__inner__action {
            column-gap: 0.75rem;
            .ant-form-item {
              margin-bottom: 0;
              .ant-input-number {
                height: 62px;
                .ant-input-number-input-wrap {
                  height: 100%;
                  input {
                    height: 100%;
                  }
                }
              }
            }
            .btn-generate {
              color: $white;
              height: auto;
              padding: 1rem 1.5rem;
              font-weight: 600;
              font-size: 1.125rem;
              line-height: 1.75rem;
              background-image: linear-gradient(to right, #1e43ff, #b318ff);
              border-radius: 0.5rem;
              min-width: 122px;
            }
          }
        }
      }
    }
  }
  .template-detail__right {
    overflow: auto;
    width: 50%;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    .template-detail__right__inner {
      margin-bottom: 6rem;

      @media (min-width: 1024px) {
        margin-bottom: auto;
      }

      .template-detail__right__inner__top {
        position: sticky;
        z-index: 999;
        background-color: $white;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $border-gray-200;
        padding: 0 0.75rem;

        .btn-new-wrapper {
          display: flex;
          align-items: center;
          padding: 0.25rem 0 0.375rem;
          column-gap: 12px;
          .btn-new-output {
            display: flex;
            align-items: center;
            padding: 0.5rem 0.75rem;
            font-size: 0.75rem;
            line-height: 1rem;
            font-weight: 500;
            border-color: transparent;
            color: $text-gray-600;
            box-shadow: none;
            &:hover {
              background-color: $bg-gray-100;
            }
            &.selected {
              color: $text-blue-700;
              background-color: $bg-gray-100;
              .number-output {
                background-color: $white;
                color: $text-blue-700;
              }
            }
            .number-output {
              margin-left: 0.5rem;
              border-radius: 50%;
              background-color: $bg-blue-700;
              padding: 0.25rem 0.5rem;
              font-size: 0.75rem;
              line-height: 1rem;
              color: $white;
            }
          }
        }
        .btn-clear {
          border: none;
          width: 51px;
          height: 32px;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: none;
          color: $text-gray-400;
          border-radius: 0.5rem;
          &:hover {
            color: $text-gray-600;
            background: $bg-gray-100;
          }
        }
      }

      .template-detail__right__inner__content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding-top: 10rem;
        padding-bottom: 10rem;
        margin-bottom: 5rem;
        flex-direction: column;
        @media (min-width: 1280px) {
          position: absolute;
          right: 0;
          left: 0;
          top: 0;
          bottom: 0;
          margin-top: 0;
        }

        .template-detail__right__video-wrapper {
          margin-bottom: 1.25rem;
          .template-detail__right__btn-video {
            outline: 2px solid transparent;
            outline-offset: 2px;
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: cover;
            border-radius: 0.5rem;
            overflow: hidden;
            width: 12rem;
            height: 126px;
            display: block;
            position: relative;
            border-color: transparent;
            @media (min-width: 768px) {
              width: 14rem;
            }

            .overlay {
              position: absolute;
              display: flex;
              height: 100%;
              width: 100%;
              align-items: center;
              justify-content: center;
              background-color: rgba(0, 0, 0, 0.3);
              color: $white;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              transition: 0.15s all cubic-bezier(0.4, 0, 0.2, 1);
              &:hover {
                background-color: rgba(0, 0, 0, 0.1);
              }
              svg {
                width: 2.25rem;
                height: 2.25rem;
              }
            }
          }
        }

        .template-detail__right__description {
          position: relative;
          max-width: 32rem;
          border-radius: 0.375rem;
          padding: 0.75rem 0;
          padding-left: 2rem;
          font-size: 0.75rem;
          line-height: 1rem;
          color: $text-gray-400;
          border: 1px solid $border-gray-200;
          .template-detail__right__description-icon {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            width: 2rem;
            align-items: center;
            justify-content: center;
            border-bottom-left-radius: 0.375rem;
            border-top-left-radius: 0.375rem;
            background-color: $bg-gray-100;
            height: 100%;
            svg {
              width: 1rem;
              height: 1rem;
              color: $text-gray-500;
            }
          }

          .template-detail__right__description-content {
            padding-left: 0.5rem;
            padding-right: 1rem;
            text-align: left;
            margin-left: 8px;
            .title {
              margin: 0;
              margin-bottom: 0.25rem;
              font-size: 0.875rem;
              line-height: 1.25rem;
              font-weight: 500;
              color: $text-gray-500;
            }
            .desc {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

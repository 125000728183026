@import "styles/variables.scss";

.signup-page {
  min-height: 100vh;
  display: flex;
  &.center {
    justify-content: center;
    align-items: center;
  }
  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    flex: 1 1;
    padding-top: 42px;
    padding-bottom: 42px;
    padding-left: 16px;
    padding-right: 16px;

    @media (min-width: 1024px) {
      padding-left: 56px;
      padding-right: 56px;
      flex: none;
    }
    @media (min-width: 1280px) {
      padding-left: 84px;
      padding-right: 84px;
    }

    .form {
      width: 336px;
      margin: 0;
      .form__logo-wrapper {
        svg {
          height: 42px;
        }
      }

      .title {
        letter-spacing: -0.025em;
        line-height: 2rem;
        font-weight: 800;
        font-size: 26px;
        margin-top: 1rem;
        margin-bottom: 0;
      }

      .subtitle {
        font-size: 12px;
        margin-top: 8px;
        color: $text-gray-600;
        margin-bottom: 0;
      }

      .form__third-party-signup {
        margin-top: 28px;
      }

      .form__divider {
        position: relative;
        margin-top: 20px;
        .form__divider__line-wrapper {
          display: flex;
          align-items: center;
          position: absolute;
          left: 0;
          top: 1px;
          width: 100%;
          height: 100%;
          .form__divider__line {
            border-top: 1px solid $border-gray-300;
            width: 100%;
          }
        }
        .form__divider__content {
          position: relative;
          line-height: 1rem;
          font-size: 0.75rem;
          display: flex;
          justify-content: center;
          span {
            color: $text-gray-500;
            padding: 0 0.5rem;
            background: $white;
          }
        }
      }

      .form__signup {
        margin-top: 28px;
        .form__signup__action {
          margin-top: 20px;
          svg {
            width: 16px;
            height: 16px;
            margin-left: 8px;
          }
        }
      }

      .form__extra {
        margin-top: 20px;
        p {
          margin-bottom: 0;
          text-align: center;
          font-size: 12px;
          color: $text-gray-400;
          a {
            color: $text-blue-400;
          }
        }
      }
    }
  }
  .signup-background {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to top right, #FF9358,#FFFF);
    display: none;
    @media (min-width: 1024px) {
      display: flex;
    }

    .introduction {
      max-width: 784px;
      margin: auto;
      @media (min-width: 640px) {
        padding-left: 20px;
        padding-right: 20px;
      }
      @media (min-width: 1024px) {
        padding-top: 64px;
        padding-bottom: 64px;
      }

      .introduction-title {
        font-weight: 700;
        font-size: 32px;
        color: $white;
        text-align: center;
        line-height: 32px;
        letter-spacing: -0.025em;
        max-width: 588px;
        margin: auto;
      }

      .agencies {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        opacity: 0.5;
        margin-top: 56px;
        column-gap: 28px;
        row-gap: 20px;
        .agency-item {
          width: max-content;
          img {
            height: 35px;
          }
        }
      }
    }
  }
}

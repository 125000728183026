@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600;700;800&display=swap');
@import 'styles/variables.scss';
@import 'styles/button.scss';
@import 'styles/form.scss';
@import 'styles/input.scss';

body {
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-layout {
  background: $white;
  font-family: 'Noto Sans', sans-serif;
}

.d-flex-align-center {
  display: flex;
  align-items: center;
}

.d-flex-center {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-flex-center-between {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-form-item {
  margin-bottom: 20px;
}

.text-center {
  text-align: center;
}

.capitalize {
  text-transform: capitalize;
}

.absolute-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.block-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}

.fixed-spinner {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
}

.ant-popover-arrow {
  display: none;
}

.ant-popover.ant-popover-placement-bottomLeft {
  padding-top: 0;
}

.text-white {
  color: $white;
}

.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.no-padding-popover {
  .ant-popover-inner-content {
    padding: 0;
  }
}

span.required {
  color: red;
  position: relative;
  top: -2px;
}

.inline-image {
  height: 50px;
  object-fit: cover;
}
.drawer-image {
  max-height: 200px;
}
.inline-image--record {
  margin: 0 8px 8px;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.form__group {
  display: flex;
  column-gap: 12px;
  .ant-col {
    height: auto;
  }
  .ant-row.ant-form-item-row {
    flex-direction: row;
  }
}

.ant-menu {
  border-inline-end: none !important;
}

.flex-full {
  flex: 1 1;
}

.relative {
  position: relative;
}

highlight {
  background-color: #f9f0ff;
  color: #531dab;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
}

.fade-up {
  box-shadow: 0 -20px 20px #fff;
}

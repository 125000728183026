@import "styles/variables.scss";

.custom-form {
  .ant-form-item {
    .ant-form-item-label {
      padding-bottom: 0.25rem;
      label {
        line-height: 1rem;
        font-weight: 600;
        font-size: 12px;
        color: $text-gray-700;
      }
    }
  }
}
